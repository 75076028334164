import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_van_search = _resolveComponent("van-search");

  const _component_van_cell = _resolveComponent("van-cell");

  const _component_van_cell_group = _resolveComponent("van-cell-group");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "选择品牌"
  }), _createVNode(_component_van_search, {
    modelValue: $setup.state.name,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.state.name = $event),
    placeholder: "搜索品牌",
    "input-align": "center",
    onSearch: $setup.brandList
  }, null, 8, ["modelValue", "onSearch"]), _createVNode(_component_van_cell_group, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.brandList, item => {
      return _openBlock(), _createBlock(_component_van_cell, {
        key: item.appid,
        title: item.name,
        "is-link": "",
        to: {
          path: '/login',
          query: {
            brandAppid: item.appid,
            brand_img: item.brand_img,
            name: item.name
          }
        }
      }, null, 8, ["title", "to"]);
    }), 128))]),
    _: 1
  })], 64);
}