import { onMounted, reactive } from "vue";
import { brand } from '@/api/index';
export default {
  name: 'Brand',

  setup() {
    const state = reactive({
      brandList: [],
      name: ''
    });

    const brandList = async () => {
      const {
        data
      } = await brand({
        name: state.name
      });
      state.brandList = data.applet_list;
    };

    onMounted(() => {
      brandList();
    });
    return {
      state,
      brandList
    };
  }

};